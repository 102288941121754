@import "../../assets/styles/utils";
footer {
    background: url(./images/footer-bg.png)no-repeat;
    background-size: cover;
    box-sizing: border-box;
    @include res(padding-top,77px,(30 / 77));
    @include res(padding-bottom,0,(xs:20px));
    .first {
        @include res(padding-left,135px,(lg:10%,xs:10px));
        @include res(padding-right,135px,(lg:10%,xs:10px));
        display: flex;
        align-items: baseline;
        justify-content: space-between;
        @include res(flex-wrap,nowrap,(xs:wrap));
        .link {
            @include res(width,306px,(xs:100%));
            border-bottom: 1px solid rgba(255,255,255,.5);
            @include res(margin-top,0,(xs:20px));
            position: relative;
            p {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                box-sizing: border-box;
                @include res(padding-bottom,36px,(15 / 36));
                @include res(padding-right,23px,(10 / 23));
                cursor: pointer;
                transition: all .3s;
                font-family: OPPOSans-L;
                font-weight: bold;
                @include res(font-size,16px,(14 / 16));
                letter-spacing: 1px;
                color: rgba(255,255,255,.5);
                &:hover {
                    color: #fff;
                }
            }
            ul {
                display: none;
                position: absolute;
                background: #fff;
                width: 100%;
                left: 0;
                box-sizing: border-box;
                padding: 20px;
                margin-top: -1px;
                li {
                    font-family: OPPOSans-L;
                    @include res(font-size,14px,(14 / 16));
                    @include res(line-height,30px,(5 / 6));
                    font-weight: bold;
                    transition: all .3s;
                    &:hover {
                        color: #1D4699;
                    }
                }
            }
        }
    }
    .center {
        border-color: rgba(125,125,125,.3);
        border-top: 1px solid;
        border-bottom: 1px solid;
        @include res(padding-left,135px,(lg:10%,xs:10px));
        @include res(padding-right,135px,(lg:10%,xs:10px));
        @include res(padding-top,90px,(2 / 9));
        @include res(padding-bottom,90px,(2 / 9));
        box-sizing: border-box;
        display: flex;
        align-items: stretch;
        justify-content: space-between;
        @include res(flex-wrap,nowrap,(xs:wrap));
        .left {
            color: rgba(255,255,255,.45);
            font-family: SourceHanSansCN-Light;
            img {
                margin-bottom: 14px;
            }
            span {
                display: block;
                @include res(font-size,14px,(12 / 14));
                letter-spacing: 1px;
                margin-bottom: 15px;
            }
            p {
                @include res(font-size,18px,(16 / 18));
                letter-spacing: 1px;
                margin-bottom: 10px;
            }
            
            h2 {
                @include res(font-size,38px,(26 / 38));
            }
        }
        .right {
            display: flex;
            align-items: stretch;
            justify-content: space-between;
            @include res(flex-wrap,nowrap,(xs:wrap));
            color: rgba(255,255,255,.4);
            letter-spacing: 1px;
            @include res(width,1070px,(lg:65%,xs:100%));
            @include res(margin-right,33px,(lg:20px,mmd:10px,md:0));
            dl {
                dt {
                    font-family: OPPOSans-R;
                    @include res(font-size,18px,(16 / 18));
                    transition: all .3s;
                    @include res(margin-bottom,35px,(3 / 7));
                }
                dd {
                    font-family: OPPOSans-L;
                    @include res(font-size,14px,(14 / 16));
                    @include res(line-height,30px,(5 / 6));
                    font-weight: bold;
                    transition: all .3s;
                    &:hover {
                        color: #fff;
                    }
                }
            }
        }
    }
    .end {
        @include res(padding-left,135px,(lg:10%,xs:10px));
        @include res(padding-right,135px,(lg:10%,xs:10px));
        box-sizing: border-box;
        @include res(height,83px,(lg:8vh,xs:auto));
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        color: rgba(255,255,255,.48);
        font-family: OPPOSans-L;
        font-weight: bold;
        @include res(font-size,16px,(14 / 16));
        letter-spacing: 1px;
        @include res(padding-top,0,(xs:10px));
        .copy {
            display: flex;
            align-items: center;
            @include res(justify-content,center);
            @include res(flex-wrap,nowrap,(xs:wrap));
            @include res(text-align,left,(xs:center));
            a {
                display: flex;
                transition: all .3s;
                @include res(margin-left,20px,(mmd:10px,xs:0));
                @include res(margin-top,0,(xs:10px));
                &:hover {
                    color: #fff;
                }
            }
        }
        .dbq {
            @include res(margin-top,0,(xs:10px));
            display: flex;
            justify-content: center;
            @include res(width,fit-content,(xs:100%));
            a {
                
                transition: all .3s;
                &:hover {
                    color: #fff;
                }
            }
        }
    }
}