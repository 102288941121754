@import "../../assets/styles/utils";
header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    @include res(background,transparent,(md:#fff));
    @include res(height,98px,(md:60px));
    @include res(padding-left,131px,(lg:90px,mmd:40px,md:20px));
    @include res(padding-right,110px,(lg:90px,mmd:40px,md:20px));
    border-bottom: 1px solid transparent;
    transition: all .5s;
    &.head1 {
        background: #fff;
        border-color: #f3f3f3;
        .head {
            .logo {
                img {
                    &.img1 {
                        display: none;
                    }
                    &.img2 {
                        display: block;
                    }
                }
            }
            .nav {
                ul {
                    li {
                        a {
                            color: #000;
                            span {
                                &:after {
                                    background-color: #000;
                                }
                            }
                        }
                        .search {
                            img {
                                &.img1 {
                                    display: none;
                                }
                                &.img2 {
                                    display: block;
                                }
                            }
                        }
                        &:hover,&.active {
                            .navchild {
                                dl {
                                    dt {
                                        a {
                                            color: #000;
                                        }
                                    }
                                }
                            }
                            a {
                                color: #1D4699;
                                span {
                                    &:after {
                                        background: #1D4699;
                                        transform: scaleX(1);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .search-box {
            input {
                border-color: rgba(29,70,153,.65);
                color: #1D4699;
                &::placeholder {
                    color: #1D4699;
                }
            }
            img {
                &.img1 {
                    display: none;
                }
                &.img2 {
                    display: block;
                }
            }
        }
    }
    .head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100%;
        .logo {
            @include res(width,3.94rem,(lg:300px,mmd:260px));
            img {
                width: 100%;
                &.img1 {
                    @include res(display,block,(md:none));
                }
                &.img2 {
                    @include res(display,none,(md:block));
                }
            }
        }
        .nav {
            @include res(display,flex,(md:none));
            height: 100%;
            ul {
                display: flex;
                align-items: center;
                height: 100%;
                li {
                    @include res(margin-right,0.6rem,(lg:25px,mmd:20px));
                    position: relative;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    &:last-child {
                        margin-right: 0;
                    }
                    a {
                        display: inline-block;
                        color: #fff;
                        font-family: OPPOSans-M;
                        @include res(font-size,20px,(lg:18px,mmd:16px));
                        transition: all .3s;
                        @include res(letter-spacing,3px,(1 / 2));
                        &:last-child {
                            margin-right: 0;
                        }
                        span {
                            position: relative;
                            height: 2px;
                            display: flex;
                            align-items: center;
                            &:after {
                                content: "";
                                width: 100%;
                                height: 2px;
                                display: block;
                                background-color: #fff;
                                position: absolute;
                                bottom: -10px;
                                left: 0;
                                z-index: 1;
                                transition: all .3s;
                                transform-origin: center;
                                transform: scaleX(0);
                            }
                        }
                        .search {
                            margin-top: 4px;
                            cursor: pointer;
                        }
                    }
                    .navchild {
                        align-items: center;
                        @include res(height,480px,(lg:400px,mmd:300px));
                        position: fixed;
                        top: 98px;
                        left: 0;
                        z-index: 9999;
                        @include res(padding-top,60px,(lg:40px,mmd:20px));
                        display: none;
                        box-sizing: border-box;
                        @include res(padding-left,15.625%,(lg:10%,mmd:8%,md:5%));
                        width: 100%;
                        box-sizing: border-box;
                        h2 {
                            font-family: SourceHanSansCN-Bold;
                            @include res(font-size,24px,(20 / 24));
                            letter-spacing: 1px;
                            color: #000000;
                            @include res(margin-bottom,30px,(lg:20px,mmd:10px));
                        }
                        .content {
                            display: flex;
                            align-items: stretch;
                            .pic {
                                @include res(margin-right,98px,(lg:60px));
                                @include res(width,548px,(lg:500px,mmd:40%));
                                img {
                                    aspect-ratio: 548 / 288;
                                    width: 100%;
                                    max-width: 100%;
                                }
                            }
                            .dl {
                                display: flex;
                                align-items: stretch;
                                dl {
                                    dt {
                                        white-space: nowrap;
                                        line-height: normal;
                                        @include res(margin-bottom,35px,(lg:30px,mmd:25px));
                                        a {
                                            color: #000;
                                            @include res(font-size,18px,(lg:16px));
                                            transition: all .3s;
                                            font-family: SourceHanSansCN-Rugular;
                                            &:hover {
                                                color: #1D4699!important;
                                            }
                                        }
                                        &:nth-child(5n) {
                                            margin-bottom: 0;
                                        }
                                    }
                                }
                                .line {
                                    width: 1px;
                                    height: auto;
                                    background: #bfbfbf;
                                    @include res(margin-left,210px,(lg:140px,mmd:70px));
                                    @include res(margin-right,210px,(lg:140px,mmd:70px));
                                }
                            }
                        }
                        
                    }
                    &:hover,&.active {
                        a {
                            span {
                                &:after {
                                    transform: scaleX(1);
                                }
                            }
                        }
                    }
                    .search {
                        img {
                            &.img1 {
                                @include res(display,block,(sm:none));
                            }
                            &.img2 {
                                @include res(display,none,(sm:block));
                            }
                        }
                    }
                }
            }
            .search {
                position: relative;
                img {
                    cursor: pointer;
                    &.img1 {
                        @include res(display,block,(sm:none));
                    }
                    &.img2 {
                        @include res(display,none,(sm:block));
                    }
                }
            }
        }
        .phone {
            @include res(display,none,(md:flex));
            position: relative;
            z-index: 1000;
            align-items: center;
            .search {
                img {
                    &.img1 {
                        display: none;
                    }
                    &.img2 {
                        display: block;
                    }
                }
            }
        }
    }
    .open-menu {
        @include res(display,none,(md:block));
        margin-left: 20px;
        position: relative;
        z-index: 100;
        // top: 50%;
        // transform: translateY(-50%);
        // right: 20px;
    }
    .mob-nav {
        width: 100%;
        height: 100vh;
        background-color: #f7f7f7;
        position: fixed;
        top: 60px;
        right: 100%;
        z-index: 99;
        color: #303030;
        box-sizing: border-box;
        overflow-y: auto;
        transition: .4s cubic-bezier(.77,0,.175,1);
        padding-top: 30px;
        .nLi {
            &.active {
                img {
                    transform: rotate(90deg);
                }
            }
            h3 {
                display: flex;
                align-items: center;
                justify-content: space-between;
                border-bottom: 1px solid rgba(0,73,130,.3);
                margin: 0 20px;
                // padding: 0 20px;
                .arrow {
                    width: 45px;
                    display: flex;
                    justify-content: end;
                }
                a {
                    flex-grow: 1;
                    line-height: 45px;
                    font-size: 16px;
                    width: fit-content;
                    display: block;
                }
            }
        }
        &.active {
            right: 0;
        }
        .sub {
            display: none;
            border-bottom: 1px solid rgba(0,73,130,.3);
            li {
                a {
                    display: block;
                    padding-left: 20px;
                    font-size: 16px;
                    line-height: 40px;
                    color: #303030;
                }
            }
        }
    }
}
.search-box {
    display: none;
    @include res(width,800px,(sm:90%));
    margin: auto;
    position: absolute;
    height: 100%;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    right: 0;
    .content {
        width: 100%;
        @include res(height,60px,(sm:50px));
    }
    input {
        border: none;
        outline: none;
        height: 100%;
        width: 100%;
        display: block;
        border-bottom: 1px solid rgba(255,255,255,.35);
        @include res(border-color,rgba(255,255,255,.35),(sm:rgba(29,70,153,.65)));
        background: transparent;
        font-family: SourceHanSansCN-Light;
        @include res(font-size,20px,(16 / 20));
        letter-spacing: 1px;
        @include res(color,#fff,(sm:#1D4699));
        &::placeholder {
            @include res(color,#fff,(sm:#1D4699));
        }
    }

    img {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 22px;
        height: 22px;
        cursor: pointer;
        &.img1 {
            @include res(display,block,(sm:none));
        }
        &.img2 {
            @include res(display,none,(sm:block));
        }
    }
}
.header-bg {
    width: 100%;
    @include res(height,480px,(lg:400px,mmd:300px));
    position: fixed;
    @include res(top,98px);
    left: 0;
    z-index: 999;
    background: #fff;
    display: none;
}