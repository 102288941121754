@import "./utils";
@import "~jason-css/src/responsive/index";
@import "../fonts/iconfont.css";

@font-face {
  font-family: 'SourceHanSansCN-Light';
  src: url('https://ziti.dongbaqu.com/SOURCEHANSANSCN-Light.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'SourceHanSansCN-Bold';
  src: url('https://ziti.dongbaqu.com/SOURCEHANSANSCN-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'SourceHanSansCN-Medium';
  src: url('https://ziti.dongbaqu.com/SOURCEHANSANSCN-Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'SourceHanSansCN-Regular';
  src: url('https://ziti.dongbaqu.com/SOURCEHANSANSCN-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'SourceHanSansCN-Normal';
  src: url('https://ziti.dongbaqu.com/SOURCEHANSANSCN-Normal.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Alibaba-PuHuiTi-Regular';
  src: url('https://ziti.dongbaqu.com/Alibaba-PuHuiTi-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'OPPOSans-M';
  src: url('https://ziti.dongbaqu.com/SOURCEHANSANSCN-Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'OPPOSans-R';
  src: url('https://ziti.dongbaqu.com/SOURCEHANSANSCN-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'OPPOSans-L';
  src: url('https://ziti.dongbaqu.com/SOURCEHANSANSCN-Light.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'PangMenZhengDao';
  src: url('https://ziti.dongbaqu.com/SOURCEHANSANSCN-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Impact';
  src: url(../fonts/impact.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

*{
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  outline:none;
  //文字中空描边
  // color: transparent;
  // -webkit-text-stroke: 1px #fff;
  // -webkit-font-smoothing: antialiased;
}

body {
  font-family:'Microsoft YaHei','微软雅黑', sans-serif;
  overflow-x: hidden;
  position: relative;
  color: $color-text;
  background-color: #fff;
  @include res(font-size,16px,(14 / 16));
}

img {
  display: block;
}
html {
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      opacity: 0.2;
      background: #014099;
  }
  &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      border-radius: 0;
      background: fade(#fff, 30%);
  }
}

//清除浮动
.clear {
  zoom: 1;

  &::after {
    content: '';
    display: block;
    height: 0;
    visibility: hidden;
    clear: both;
  }
}


//主体内容部分宽度
.container {
  box-sizing: content-box;
  width: 100%;
  max-width: 1440px;
  @include res(padding-left, $container-gutter-base, $container-gutter-ratio);
  @include res(padding-right, $container-gutter-base, $container-gutter-ratio);

  &.container-1200 {
    max-width: 1200px;
  }
}

@media screen and (max-width: 1600px) {
  .container {
    box-sizing: border-box;
    max-width: 1330px;
  }
}

@media screen and (max-width: 1440px) {
  .container {
    max-width: 1200px;
  }
}
@media screen and (max-width: 1024px) {
  .container {
    max-width: 960px;
  }
}
@media screen and (max-width: 768px) {
  .container {
    max-width: 700px;
  }
}


//圆形进度条
@keyframes progress {
  0% {
    stroke-dashoffset: 400%;
  }

  50% {
    stroke-dashoffset: 200%;
  }

  100% {
    stroke-dashoffset: 0%;
  }
}

@keyframes progress1 {
  0% {
    stroke-dashoffset: 400%;
  }

  50% {
    stroke-dashoffset: 200%;
  }

  100% {
    stroke-dashoffset: 0%;
  }
}

@keyframes progress2 {
  0% {
    stroke-dashoffset: 400%;
  }

  50% {
    stroke-dashoffset: 200%;
  }

  100% {
    stroke-dashoffset: 0%;
  }
}

@keyframes rain {
  0% {
    opacity: 0;
    transform: translateY(-20%);
  }

  25% {
    opacity: 0.7;
    transform: translateY(-10%);
  }

  50% {
    opacity: 1;
    transform: translateY(0%);
  }

  75% {
    opacity: 0.7;
    transform: translateY(10%);
  }

  100% {
    opacity: 0;
    transform: translateY(20%);
  }
}
.nei-banner {
  position: relative;
  @include res(height,auto,(xs:200px));
  @include res(margin-top,98px,(xs:60px));
  img {
    max-width: 100%;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.nei-nav {
  display: flex;
  align-items: center;
  justify-content: center;
  @include res(height,110px,(6 / 11));
  box-sizing: border-box;
  border-bottom: 2px solid hsla(0, 0%, 93.3%, .8);
  overflow: hidden;
  overflow-x: auto;
  ul {
    display: flex;
    align-items: center;
    justify-content: center;
    li {
      @include res(font-size,20px,(4 / 5));
      font-family: OPPOSans-R;
      color: #000000;
      @include res(letter-spacing,2px,(1 / 2));
      border-right: 1px solid #707070;
      @include res(padding-left,45px,(10 / 45));
      @include res(padding-right,45px,(10 / 45));
      transition: all .3s;
      a {
        position: relative;
      }
      span {
        position: absolute;
        @include res(bottom,-42px,(6 / 11));
        width: 100%;
        left: 0;
        @include res(height,2px,(1 / 2));
        background: transparent;
        transition: all .3s;
      }
      &:last-child {
        border: none;
      }
      &:hover,&.active {
        color: #014099;
        span {
          background: #014099;
        }
      }
    }
  }

}
.nei-title {
  h2 {
    font-family: PangMenZhengDao;
    @include res(font-size,36px,(24 / 36));
    @include res(letter-spacing,2px,(1 / 2));
    color: #014099;
  }
  span {
    display: block;
    @include res(width,29px,(20 / 29));
    @include res(height,4px,(1 / 2));
    background-color: #014099;
    @include res(margin-top,15px,(2 / 3));
  }
}
.nei-humburger {
  width: fit-content;
  display: flex;
  align-items: center;
  font-family: OPPOSans-L;
  @include res(margin-top,35px,(15 / 35));
  @include res(margin-bottom,35px,(15 / 35));
  @include res(font-size,16px,(14 / 16));
  @include res(letter-spacing,2px,(1 / 2));
  margin-left: auto;
  color: #000000;
  a {
    transition: all .3s;
    &.active,&:hover {
      color: #014099;
      font-family: OPPOSans-M;
    }
  }
  span {
    @include res(margin-left,10px,(1 / 2));
    @include res(margin-right,10px,(1 / 2));
    &:last-child {
      display: none;
    }
  }
}
.swiper-button-prev:after, .swiper-rtl .swiper-button-next:after {
  content: "";
}
.swiper-button-next:after, .swiper-rtl .swiper-button-prev:after {
  content: "";
}