@import "../../assets/styles/utils";
.pages {
    ul {
        display: flex;
        align-items: center;
        justify-content: center;
        li {
            @include res(width,60px,(4 / 6));
            @include res(height,60px,(4 / 6));
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: OPPOSans-L;
            border-radius: 50%;
            @include res(font-size,16px,(14 / 16));
            color: #6e6e6e;
            transition: all .3s;
            @include res(margin-right,10px);
            a {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
            }
            &:last-child {
                margin-right: 0;
                
            }
            &:hover,&.active {
                background: #014099;
                color: #fff;
            }
            span {
                @include res(font-size,30px,(2 / 3));
            }
        }
    }
}